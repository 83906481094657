import { calculateAspectRatioFit } from '@/utils/index';
import { isEmpty } from 'lodash';
const Pdfjs = require('pdfjs-dist');
const WORKER_URL = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${Pdfjs.version}/pdf.worker.min.js`;
Pdfjs.GlobalWorkerOptions.workerSrc = WORKER_URL;
/**
 * Class to render pdfs
 */
class PdfDistRenderer {
  #canvasEl = null;
  #src = '';
  #worker = '';
  #width = 0;
  #height = 0;
  #document = {
  };
  #loadingTask = {
  }
  constructor({ canvasEl, src, width = 0, height = 0 }) {
    this.#canvasEl = canvasEl;
    this.#src = src;
    this.#width = width;
    this.#height = height;
    this.createWorker();
  }
  getDocument() {
    const { worker, src } = this;
    return Pdfjs.getDocument({
      url: src,
      worker,
    });
  }
  get src() {
    return this.#src;
  }
  /**
   * @param page
   * @return {Promise<PdfDistRenderer>}
   */
  async loadDocument() {
    try {
      this.#loadingTask = await this.getDocument();
      this.#document = await this.loadingTask.promise;
      return this;
    } catch (e) {
      console.log(e);
    }
  }
  get loadingTask() {
    return this.#loadingTask;
  }
  /**
   * @param pageNum
   * @param isThumbnail
   * @param thumbnailsSettings
   * @param reduceProportions reduce width to given proportions
   * @return {Promise<boolean>}
   */
  async renderAsThumbnail({ pageNum, thumbnailsSettings, fitToAspectRatio = false, reduceProportions = 100 } = {
  }) {
    try {
      const canvasEl = this.#canvasEl;
      if (!canvasEl?.offsetWidth) return false;
      await this.loadDocument();
      const page = await this.getPage(pageNum);
      const { width = 0, height = 0 } = thumbnailsSettings ?? {
      };
      if (fitToAspectRatio && thumbnailsSettings) {
        const { view = [] } = page ?? {
        };
        const [widthPdf, heightPdf] = [view[2], view[3]];
        const sizeSettings = calculateAspectRatioFit(widthPdf, heightPdf, width, height, (reduceProportions / 100));
        if (!canvasEl) {
          console.error('No canvas instance was provided in constructor');
          return false;
        }
        canvasEl.style.height = `${sizeSettings.height}px`;
        canvasEl.style.width = `${sizeSettings.width}px`;
      }
      return this.render({
        page,
      });
    } catch (e) {
      console.log(e);
    }
  }
  /**
   * @desc destroy pdf document instance
   * @return {Promise<PdfDistRenderer>}
   */
  async destroy() {
    try {
      if (isEmpty(this.#document)) return this;
      await this.#document.destroy();
      return this;
    } catch (e) {
      console.log(e);
    }
  }
  /**
   * @param {PDFPageProxy} page
   * @param countHeightFromViewport
   * @return boolean
   */
  render({ page } = {
  }) {
    try {
      const canvasEl = this.#canvasEl;
      if (!canvasEl) {
        console.error('No canvas instance was provided in constructor');
        return false;
      }
      let viewport = page.getViewport({
        scale: 1.0,
      });
      const scale = canvasEl?.offsetWidth / viewport.width * (window.devicePixelRatio || 1);
      viewport = page.getViewport({
        scale,
      });
      canvasEl.height = viewport.height;
      canvasEl.width = viewport.width;
      page.render({
        viewport,
        canvasContext: canvasEl.getContext('2d'),
      });
      return true;
    } catch (e) {
      console.log(e);
    }
  }
  async getPage(pageNum = 1) {
    if (isEmpty(this.#document)) {
      console.error('Please load document first');
      return;
    }
    return await this.#document.getPage(pageNum);
  }
  get worker() {
    return this.#worker;
  }
  createWorker() {
    this.#worker = new Pdfjs.PDFWorker();
    return this;
  }
}
const createPdfRenderer = rendererOptions => new PdfDistRenderer(rendererOptions);
export {
  createPdfRenderer,
  PdfDistRenderer,
};
