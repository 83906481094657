<template>
  <span class="pdf-renderer__wrapper">
    <canvas
      ref="canvas"
      class="pdf-renderer__canvas"
      style="width: 100%; height: 100%;" />
  </span>
</template>
<script>
import { createPdfRenderer } from '@/utils/PdfDistRenderer';
import { mapMutations } from 'vuex';
export default {
  name: 'AppPdfRenderer',
  props: {
    /**
     * @type string
     * Src of pdf to render
     */
    src: {
      type: String,
      required: true,
    },
    /**
     * page of pdf to render
     * @type number
     */
    page: {
      type: Number,
      default: 1,
    },
    /**
     * @todo
     * @type number
     */
    rotate: {
      type: Number,
      default: 90,
    },
    /**
     * @type Object
     */
    sizeSettings: {
      type: Object,
      default: null,
    },
    /**
     * @type boolean
     */
    disabledCustomSizePdf: {
      type: Boolean,
      default: false,
    },
    /**
     * @type Number
     */
    reduceProportions: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isRendered: false,
      pdfRenderer: null,
    };
  },
  watch: {
    src: {
      async handler(val) {
        this.spinner(true);
        await this.$nextTick();
        const { $refs } = this;
        if (!val) {
          this.spinner(false);
          return;
        }
        const canvasEl = $refs?.canvas;
        if (!canvasEl) {
          console.error('Empty canvas');
          this.spinner(false);
          return;
        }
        if (this.pdfRenderer) {
          await this.pdfRenderer.destroy();
        }
        this.pdfRenderer = createPdfRenderer({
          src: val,
          canvasEl,
        });
        await this.renderAsThumbnail();
        this.spinner(false);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    async renderAsThumbnail() {
      const { sizeSettings, disabledCustomSizePdf, reduceProportions } = this;
      this.isRendered = await this.pdfRenderer.renderAsThumbnail({
        pageNum: 1,
        thumbnailsSettings: sizeSettings,
        fitToAspectRatio: !disabledCustomSizePdf,
        reduceProportions: reduceProportions,
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .pdf-renderer {
    &__wrapper {
      position: relative;
      display: block;
    }
    &__canvas {
      display: inline-block;
      vertical-align: top;
    }
  }
</style>
