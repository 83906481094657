<template>
  <component
    :is="component"
    ref="pdf"
    v-bind="$attrs"
    v-on="$listeners" />
</template>
<script>
import { mapGetters } from 'vuex';
import appPdfRenderer from '@/components/App/AppPdfRenderer';
import pdf from 'vue-pdf';
export default {
  name: 'AppProxyPdf',
  components: {
    appPdfRenderer,
    pdf,
  },
  computed: {
    ...mapGetters('FeatureFlags', ['useNewPdfLibrary']),
    component() {
      if (this.useNewPdfLibrary) {
        return 'appPdfRenderer';
      }
      return 'pdf';
    },
    componentInstance() {
      return this.$refs.pdf;
    },
  },
};
</script>
<style scoped>
</style>
